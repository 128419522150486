import React, { Suspense } from 'react'
import { HashRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import AppHeader from './views/components/AppHeader';
import AppFooter from './views/components/AppFooter';
import AppLoader from './views/components/AppLoader';
import Home from './views/pages/Home';
import About from './views/pages/About';
import Contact from './views/pages/Contact';
import Industries from './views/pages/Industries';
import Products from './views/pages/Products';
import Checkout from './views/pages/Products/checkout';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

function App() {
  return (
    <HashRouter>
      <div className="App">
        <AppHeader />
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route exact path="/home" name="Home" element={<Home />} />
            <Route exact path="/about" name="About" element={<About />} />
            <Route exact path="/contact" name="Contact" element={<Contact />} />
            <Route exact path="/industries" name="Industries" element={<Industries />} />
            <Route exact path="/products" name="Products" element={<Products />} />
            <Route exact path="/products/checkout" name="Checkout" element={<Checkout />} />
            <Route exact path="/cart" name="Cart" element={<Checkout />} />
            <Route path="*" name="Home" element={<Home />} />
          </Routes>
        </Suspense>
        <AppLoader />
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          theme="light"
          limit={1}
        />
        <AppFooter />
      </div>
    </HashRouter>
  );
}

export default App;
