import { useEffect } from 'react'
import { Row, Col, Form, Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

const OTP = ({ ShowOtpModal, setShowOtpModal, IsUserRegistered, createOrder }) => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm()

    useEffect(() => {
        if (ShowOtpModal) {
            reset()
        }
    }, [ShowOtpModal])

    const onError = (errors) => {
        console.log(errors)
    }

    return (
        <Modal size="md" centered show={ShowOtpModal} onHide={() => setShowOtpModal(false)}>
            <Modal.Header closeButton className="border-0 p-0">
                <Modal.Title className="m-auto text-center">
                    Verify OTP
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(createOrder, onError)}>
                    <Row className="form-container">
                        <Form.Group as={Col} md={12}>
                            <Form.Label className='text-dark'>OTP</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter OTP"
                                {...register('otp', { required: 'OTP is required' })}
                            />
                            {errors.otp && <Form.Text className="text-danger">{errors.otp.message}</Form.Text>}
                        </Form.Group>
                        {
                            !IsUserRegistered && (
                                <Form.Group as={Col} md={12} className='mt-4'>
                                    <Form.Label className='text-dark'>Create your password</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Password"
                                        {...register('password', { required: 'Password is required' })}
                                    />
                                    {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}
                                </Form.Group>
                            )
                        }
                    </Row>
                    <Modal.Footer className="border-0 text-center p-0 mt-4">
                        <Button type="submit" variant="success" className="m-auto">
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default OTP