import axios from "axios"

const axiosClient = axios.create({
  // baseURL: 'http://localhost:3005'
  baseURL: 'https://api.globaltracking.ai'
})

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['token'] = token
    }
    return config;
  }
)

axiosClient.interceptors.response.use(
  (response) => {
    if (response.data && response.data.code && response.data.code === 401) {
      localStorage.clear()
      window.location.href = window.location.origin
    }
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosClient