import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Container, Row, Col, Table, Form, Button, Modal, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import axios from '../../../axios'

import OTP from './otp'

import TrackerAirFreight from './../../../assets/images/tracker-air-freight.png'
import TrackerMaritime from './../../../assets/images/tracker-maritime-tracker.png'

import SectionConnectBg1 from './../../../assets/images/section-2-bg-1.svg'
import XCircle from './../../../assets/images/x-circle.svg'

const Checkout = () => {
    const dispatch = useDispatch()

    const [Products, setProducts] = useState([])
    const [CartItems, setCartItems] = useState([])

    const { register, handleSubmit, formState: { errors }, reset } = useForm()

    const [IsUserRegistered, setIsUserRegistered] = useState(true)
    const [ShowOtpModal, setShowOtpModal] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [ErrorMessage, setErrorMessage] = useState()
    const [DiffBillingAddress, setDiffBillingAddress] = useState(true)

    const Quantity = Array.from({ length: 100 }).map((_, i) => { return { value: i + 1, label: (i + 1).toString().padStart(2, 0) } })
    const maxQuantityAllowed = 100

    const ShippingMethods = [
        {
            name: 'FedEx Ground Home Delivery',
            price: 36.73,
            days: 4
        },
        {
            name: 'USPS Priority Mail',
            price: 55.03,
            days: 2
        },
        {
            name: 'FedEx Express Saver',
            price: 108.73,
            days: 3
        },
        {
            name: 'USPS Priority Mail Express',
            price: 100.83,
            days: 1
        },
        {
            name: 'FedEx Standard Overnight',
            price: 207.61,
            days: 1
        }
    ]

    const [OrderData, setOrderData] = useState({})

    useEffect(() => {
        getProducts()
    }, [])

    const getProducts = () => {
        dispatch({ type: 'set', loader: true })
        axios.get('public/products/get')
            .then(res => {
                dispatch({ type: 'set', loader: false })
                setProducts(res?.data?.data || [])
                getCartItems()
            })
            .catch(err => {
                dispatch({ type: 'set', loader: false })
                console.log(err)
            })
    }

    const getCartItems = () => {
        let carts = localStorage.getItem('carts')
        if (carts) {
            setProducts(products => {
                let items = JSON.parse(carts).map(cart => {
                    let product = products.find(prod => prod._id === cart.product)
                    if (product) {
                        let quantity = cart.quantity
                        if (quantity > maxQuantityAllowed) {
                            quantity = maxQuantityAllowed
                        }
                        let plan = product.plans.find(plan => plan.minDevice <= quantity && plan.maxDevice >= quantity)
                        return {
                            product: product,
                            quantity: quantity,
                            plan: plan
                        }
                    } else {
                        return null
                    }
                }).filter(c => c)
                setCartItems(items)
                return products
            })
        }
    }

    const updateItemQuantity = (index, quantity) => {
        let item = [...CartItems]
        item[index].quantity = +quantity
        localStorage.setItem('carts', JSON.stringify(item.map(d => { return { product: d.product._id, quantity: d.quantity } })))
        getCartItems()
    }

    const removeItem = (index) => {
        let item = [...CartItems].filter((_, i) => i !== index)
        localStorage.setItem('carts', JSON.stringify(item.map(d => { return { product: d.product._id, quantity: d.quantity } })))
        getCartItems()
    }

    const verifyOrder = (data) => {
        setOrderData({
            ...data,
            items: CartItems.map(item => {
                return {
                    product: item.product._id,
                    qty: item.quantity
                }
            })
        })
        dispatch({ type: 'set', loader: true })
        axios.post('public/order/verify', data)
            .then(res => {
                dispatch({ type: 'set', loader: false })
                if (res.data.status) {
                    toast.success(res.data.message)
                    setIsUserRegistered(res.data.userRegistered)
                    setShowOtpModal(true)
                } else {
                    setErrorMessage(res.data.message)
                    setShowErrorModal(true)
                }
            })
            .catch(err => {
                dispatch({ type: 'set', loader: false })
                setErrorMessage(err.message || 'Something went wrong')
                setShowErrorModal(true)
            })
    }

    const createOrder = (data) => {
        dispatch({ type: 'set', loader: true })
        axios.post('public/order/create', {
            ...data,
            ...OrderData,
            name: `${OrderData.firstName} ${OrderData.lastName}`
        })
            .then(res => {
                dispatch({ type: 'set', loader: false })
                if (res.data.status) {
                    setShowOtpModal(false)
                    setShowSuccessModal(true)
                    reset()
                } else {
                    setErrorMessage(res.data.message)
                    setShowErrorModal(true)
                }
            })
            .catch(err => {
                dispatch({ type: 'set', loader: false })
                setErrorMessage(err.message || 'Something went wrong')
                setShowErrorModal(true)
            })
    }

    const onError = (errors) => {
        console.log(errors)
    }

    const handleModalClose = () => {
        setShowSuccessModal(false)
        setShowErrorModal(false)
    }

    return (
        <>
            <section className="page-banner product-section-1">
                <Container>
                    <Row>
                        <Col md={12} className="text-center mb-4 pb-4">
                            <div className="text-large">
                                Checkout
                            </div>
                            <div className="text-small">
                                Lorem ipsum dolor sit amet consectetur. Lectus convallis
                                <br />ullamcorper facilisi ac quam semper dui vel at.
                            </div>
                        </Col>
                    </Row>
                </Container>

                {
                    CartItems.length > 0
                        ? (
                            <Container>
                                <Table responsive className="cart-table">
                                    <thead>
                                        <tr>
                                            <th className="text-start">SN.</th>
                                            <th className="text-center">Procuct</th>
                                            {/* <th className="text-center">Save</th> */}
                                            <th className="text-center">Quantity</th>
                                            <th className="text-center" style={{ minWidth: 220 }}>Unit Price</th>
                                            <th className="text-center" style={{ minWidth: 150 }}>Price</th>
                                            <th className="text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            CartItems.map((item, index) => {
                                                return <tr key={index}>
                                                    <td className="text-start">{(index + 1).toString().padStart(2, 0)}</td>
                                                    <td className="text-start">
                                                        <div className="d-flex justify-content-center gap-4">
                                                            <img src={item.product.name === 'Air Freight Tracker' ? TrackerAirFreight : TrackerMaritime} alt={item.product.name} width="160" height={130} />
                                                            <div>
                                                                <div className="card-product-title mt-2">
                                                                    The GTAI {item.product.name}
                                                                    <div className="mt-1">
                                                                        ({item.plan.name})
                                                                    </div>
                                                                </div>
                                                                <div className="card-product-description mt-2">
                                                                    Up to {item.plan.maxDevice === Number.MAX_VALUE ? `50+` : item.plan.maxDevice} Devices
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {/* <td className="text-center">♡</td> */}
                                                    <td>
                                                        <Form.Select
                                                            className="quantity m-auto"
                                                            value={item.quantity}
                                                            onChange={(e) => updateItemQuantity(index, e.target.value)}
                                                        >
                                                            {
                                                                Quantity.map(((quant, i) => {
                                                                    return <option key={i} value={quant.value}>{quant.label}</option>
                                                                }))
                                                            }
                                                        </Form.Select>
                                                    </td>
                                                    <td className="text-center">${item.plan.price}</td>
                                                    <td className="text-center">${item.plan.price * item.quantity}</td>
                                                    <td className="text-center">
                                                        <a className="remove-item" onClick={() => removeItem(index)}>
                                                            <img src={XCircle} alt="XCircle" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        {/* <tr>
                                <td className="text-start">01</td>
                                <td className="text-start">
                                    <div className="d-flex justify-content-center gap-4">
                                        <img src={TrackerAirFreight} alt="TrackerAirFreight" width="160" />
                                        <div>
                                            <div className="card-product-title mt-2">
                                                The GTAI Air Freight Tracker
                                                <div className="mt-1">
                                                    (Basic)
                                                </div>
                                            </div>
                                            <div className="card-product-description mt-2">
                                                Up to 14 Devices
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-center">♡</td>
                                <td>
                                    <Form.Select className="quantity m-auto">
                                        {
                                            Quantity.map(((quant, i) => {
                                                return <option key={i} value={quant.value}>{quant.label}</option>
                                            }))
                                        }
                                    </Form.Select>
                                </td>
                                <td className="text-center">$299.00</td>
                            </tr> */}
                                    </tbody>
                                </Table>
                                <Form onSubmit={handleSubmit(verifyOrder, onError)}>
                                    <Row>
                                        <Col md={7}>
                                            <div className="cart-form-box">
                                                <div className="form-name"> Billing details: </div>
                                                <div className="form-border">
                                                    <Row className="form-container">
                                                        <Form.Group as={Col} md={6}>
                                                            <Form.Label>First Name *</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="First Name *"
                                                                {...register('firstName', { required: 'First Name is required' })}
                                                            />
                                                            {errors.firstName && <Form.Text className="text-danger">{errors.firstName.message}</Form.Text>}
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={6}>
                                                            <Form.Label>Last Name *</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Last Name *"
                                                                {...register('lastName', { required: 'Last Name is required' })}
                                                            />
                                                            {errors.lastName && <Form.Text className="text-danger">{errors.lastName.message}</Form.Text>}
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={6}>
                                                            <Form.Label>Email *</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                placeholder="Email *"
                                                                {...register('email', {
                                                                    required: 'Email is required',
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                                        message: 'Invalid email address'
                                                                    }
                                                                })}
                                                            />
                                                            {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={6}>
                                                            <Form.Label>Phone *</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder="Phone *"
                                                                {...register('phone', { required: 'Phone is required' })}
                                                            />
                                                            {errors.phone && <Form.Text className="text-danger">{errors.phone.message}</Form.Text>}
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={12}>
                                                            <Form.Label> Billing Address * </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Billing Address *"
                                                                {...register('billing.address', { required: 'Billing Address is required' })}
                                                            />
                                                            {errors.billing?.address && <Form.Text className="text-danger">{errors.billing.address.message}</Form.Text>}
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={4}>
                                                            <Form.Label>Town/ City *</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Town/ City *"
                                                                {...register('billing.city', { required: 'Town/ City is required' })}
                                                            />
                                                            {errors.billing?.city && <Form.Text className="text-danger">{errors.billing.city.message}</Form.Text>}
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={4}>
                                                            <Form.Label>State *</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="State *"
                                                                {...register('billing.state', { required: 'State is required' })}
                                                            />
                                                            {errors.billing?.state && <Form.Text className="text-danger">{errors.billing.state.message}</Form.Text>}
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={4}>
                                                            <Form.Label>Zip *</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder="Zip"
                                                                {...register('billing.zip', { required: 'Zip is required' })}
                                                            />
                                                            {errors.billing?.zip && <Form.Text className="text-danger">{errors.billing.zip.message}</Form.Text>}
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={12}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="ShippingAddress"
                                                                label="Shipping Address same as Billing Address"
                                                                checked={DiffBillingAddress}
                                                                onChange={() => setDiffBillingAddress(!DiffBillingAddress)}
                                                            />
                                                        </Form.Group>
                                                        {
                                                            !DiffBillingAddress && (
                                                                <>
                                                                    <Form.Group as={Col} md={12}>
                                                                        <Form.Label> Shipping Address * </Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Shipping Address *"
                                                                            {...register('shipping.address', { required: 'Shipping Address is required' })}
                                                                        />
                                                                        {errors.shipping?.address && <Form.Text className="text-danger">{errors.shipping.address.message}</Form.Text>}
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={4}>
                                                                        <Form.Label>Town/ City *</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Town/ City *"
                                                                            {...register('shipping.city', { required: 'Town/ City is required' })}
                                                                        />
                                                                        {errors.shipping?.city && <Form.Text className="text-danger">{errors.shipping.city.message}</Form.Text>}
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={4}>
                                                                        <Form.Label>State *</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="State *"
                                                                            {...register('shipping.state', { required: 'State is required' })}
                                                                        />
                                                                        {errors.shipping?.state && <Form.Text className="text-danger">{errors.shipping.state.message}</Form.Text>}
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={4}>
                                                                        <Form.Label>Zip *</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Zip"
                                                                            {...register('shipping.zip', { required: 'Zip is required' })}
                                                                        />
                                                                        {errors.shipping?.zip && <Form.Text className="text-danger">{errors.shipping.zip.message}</Form.Text>}
                                                                    </Form.Group>
                                                                </>
                                                            )
                                                        }
                                                        <Form.Group as={Col} md={12}>
                                                            <Form.Label>Comment *</Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                rows={5}
                                                                placeholder="What would you like to discuss?"
                                                                style={{ height: '150px' }}
                                                                {...register('comment', { required: 'Comment is required' })}
                                                            />
                                                            {errors.comment && <Form.Text className="text-danger">{errors.comment.message}</Form.Text>}
                                                        </Form.Group>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={5}>
                                            <div className="cart-form-box">
                                                <div className="form-name"> Shipping Method: </div>
                                                <Row className="delivery-methods">
                                                    {
                                                        ShippingMethods.map((shipping, index) => {
                                                            return <Form.Group key={index} as={Col} md={12}>
                                                                <Form.Check key={index} type="radio" id={`ShippingMethod${index}`}>
                                                                    <Form.Check.Input
                                                                        type="radio"
                                                                        name="ShippingMethod"
                                                                        value={shipping.name}
                                                                        {...register("shippingMethod", { required: "Please select an option." })}
                                                                    />
                                                                    <Form.Check.Label>
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <div>
                                                                                <div className="delivery-name"> {shipping.name} </div>
                                                                                <div className="delivery-time"> {shipping.days} business days </div>
                                                                            </div>
                                                                            <div className="delivery-price"> ${shipping.price} </div>
                                                                        </div>
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </Form.Group>
                                                        })
                                                    }
                                                    {errors.shippingMethod && <Form.Text className="text-danger">{errors.shippingMethod.message}</Form.Text>}
                                                </Row>
                                            </div>
                                            <Button type="submit" className="mt-4" variant='success'> Place Order </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Container>
                        )
                        : (
                            <div className="d-flex justify-content-center">
                                <NavLink to="/products">
                                    <Button variant="primary">Go To Product</Button>
                                </NavLink>
                            </div>
                        )
                }
            </section >

            <section className="about-section-3">
                <Container>
                    <div className="text-center text-large">
                        We lead the evolution of product tracking
                        <br /> with secure, smart solutions.
                    </div>
                    <div className="text-center mt-4 pt-4">
                        <NavLink to="/contact">
                            <Button variant="success"> Request A Demo </Button>
                        </NavLink>
                    </div>
                    <img className="bg-1" src={SectionConnectBg1} alt="Bg 1" />
                </Container>
            </section>

            <OTP ShowOtpModal={ShowOtpModal} setShowOtpModal={setShowOtpModal} IsUserRegistered={IsUserRegistered} createOrder={createOrder} />

            <Modal size="lg" centered show={showSuccessModal} onHide={handleModalClose}>
                <Modal.Header closeButton className="border-0 p-0">
                    <Modal.Title className="m-auto text-center">
                        Thank You For Ordering
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <p style={{ fontSize: '16px' }} className="text-muted">
                        We have received your order. We'll reach
                        <br />you out shortly!
                    </p>
                </Modal.Body>
                <Modal.Footer className="border-0 text-center p-0">
                    <Button variant="success" className="m-auto" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" centered show={showErrorModal} onHide={handleModalClose}>
                <Modal.Header closeButton className="border-0 p-0">
                    <Modal.Title className="m-auto text-center text-danger" style={{ fontSize: '40px' }}>
                        Error!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <p style={{ fontSize: '16px' }} className="text-muted">
                        {ErrorMessage}
                        <br />
                        Please try again.
                    </p>
                </Modal.Body>
                <Modal.Footer className="border-0 text-center p-0">
                    <Button variant="danger" className="m-auto" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Checkout