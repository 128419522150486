import React, { useEffect, useState } from 'react'
import { useLocation, NavLink } from 'react-router-dom'
import { Container, Nav, Navbar, Button, Offcanvas, Badge } from 'react-bootstrap'

import logo from './../../assets/logo/logo.png'

const AppHeader = () => {
    const location = useLocation()

    const [Show, setShow] = useState(false)
    const [isScrolled, setIsScrolled] = useState(false)

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
        setIsScrolled(false)
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [location])

    const handleScroll = () => {
        const offset = window.scrollY
        if (offset > 30 && offset < 60) { } else {
            if (offset > 50) {
                setIsScrolled(true)
            } else {
                setIsScrolled(false)
            }
        }
    }

    return (
        <>
            <Navbar className={isScrolled ? 'active' : ''}>
                <Container>
                    <Button className="d-md-none hamburger" onClick={() => setShow(true)}>
                        <div className="line-1"></div>
                        <div className="line-2"></div>
                        <div className="line-3"></div>
                    </Button>
                    <Navbar.Brand>
                        <NavLink className="nav-link" to="/">
                            <img src={logo} className="App-logo" alt="logo" />
                        </NavLink>
                    </Navbar.Brand>
                    <Nav className="d-none d-md-flex">
                        <NavLink className="nav-link" to="/home">Home</NavLink>
                        <NavLink className="nav-link" to="/about">About</NavLink>
                        <NavLink className="nav-link" to="/industries">Industries</NavLink>
                        <NavLink className="nav-link" to="/products">Products</NavLink>
                        <NavLink className="nav-link" to="/contact">Contact</NavLink>
                        <NavLink className="nav-link" to="/cart">
                            Cart
                            {/* <Badge className="ms-2" bg="primary">9</Badge> */}
                        </NavLink>
                        <NavLink className="nav-link nav-button" to="https://portal.globaltracking.ai" target="_blank">Log In</NavLink>
                        {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">
                                Separated link
                            </NavDropdown.Item>
                        </NavDropdown> */}
                    </Nav>
                </Container>
            </Navbar>

            <Offcanvas show={Show} onHide={() => setShow(false)}>
                <Offcanvas.Header closeButton closeVariant="white">
                    <Offcanvas.Title>
                        <img src={logo} className="App-logo" alt="logo" />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav>
                        <NavLink className="nav-link" onClick={() => setShow(false)} to="/home">Home</NavLink>
                        <NavLink className="nav-link" onClick={() => setShow(false)} to="/about">About</NavLink>
                        <NavLink className="nav-link" onClick={() => setShow(false)} to="/industries">Industries</NavLink>
                        <NavLink className="nav-link" onClick={() => setShow(false)} to="/products">Products</NavLink>
                        <NavLink className="nav-link" onClick={() => setShow(false)} to="/contact">Contact</NavLink>
                        <NavLink className="nav-link nav-button" onClick={() => setShow(false)} to="https://portal.globaltracking.ai" target="_blank">Log In</NavLink>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas >
        </>
    )
}

export default AppHeader